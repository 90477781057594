import {
  crimson,
  slate,
  crimsonDark,
  violetDark,
  slateDark,
  greenDark,
  iris,
  irisDark,
  gray,
  green,
  violetDarkA,
  whiteA,
  redDark,
  red,
  blackA,
  violet,
  violetA,
  indigo,
} from '@radix-ui/colors'
import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { reset } from 'utils/css/reset'
import { Inter } from '@next/font/google'
import localFont from 'next/font/local'
import { head } from 'lodash'

const headerFont = localFont({
  src: 'pages/fonts/Atirose/AtiroseVintage.woff2',
})
const bodyFont = localFont({ src: 'pages/fonts/Milford/Milford.ttf' })

const inter = Inter({
  subsets: ['latin'],
})

export const { createTheme, keyframes, styled, globalCss, getCssText } =
  createStitches({
    theme: {
      colors: {
        ...gray,
        ...crimson,
        ...violet,
        ...violetA,
        ...slate,
        ...red,
        ...whiteA,
        ...blackA,
        ...iris,
        ...green,
        ...indigo,

        // Sage variations
        sage1: '#F5F8ED',
        sage2: '#EBF1DB',
        sage3: '#E1EACA',
        sage4: '#D7E3B8',
        sage5: '#CDDCA6',
        sage6: '#C3D595',
        sage7: '#ABC470', // Original sage color
        sage8: '#93AC58',
        sage9: '#7B9440',
        sage10: '#637C28',
        sage11: '#4B6410',
        sage12: '#334C00',

        // Lavender variations
        lavender1: '#F4F1FA',
        lavender2: '#E9E3F5',
        lavender3: '#DED5F0',
        lavender4: '#D3C7EB',
        lavender5: '#C8B9E6',
        lavender6: '#BDABE1',
        lavender7: '#9780C4', // Original lavender color
        lavender8: '#7F66AC',
        lavender9: '#674D94',
        lavender10: '#4F337C',
        lavender11: '#371A64',
        lavender12: '#1F004C',

        // Coral variations
        coral1: '#FDF0EE',
        coral2: '#FBE1DD',
        coral3: '#F9D2CC',
        coral4: '#F7C3BB',
        coral5: '#F5B4AA',
        coral6: '#F3A599',
        coral7: '#DF6D5D', // Original coral color
        coral8: '#C75345',
        coral9: '#AF392D',
        coral10: '#971F15',
        coral11: '#7F0500',
        coral12: '#670000',

        // Ivory variations
        ivory1: '#FDFDFB',
        ivory2: '#FBFBF7',
        ivory3: '#F9F9F3',
        ivory4: '#F7F7EF',
        ivory5: '#F5F5EB',
        ivory6: '#F6F4E7', // Original ivory color
        ivory7: '#E2E0D3',
        ivory8: '#CECBBF',
        ivory9: '#BAB7AB',
        ivory10: '#A6A397',
        ivory11: '#928F83',
        ivory12: '#7E7B6F',

        // Onyx variations
        onyx1: '#1A1A1A',
        onyx2: '#262626',
        onyx3: '#333333',
        onyx4: '#404040',
        onyx5: '#4D4D4D',
        onyx6: '#595959',
        onyx7: '#666666',
        onyx8: '#737373',
        onyx9: '#808080',
        onyx10: '#8C8C8C',
        onyx11: '#999999',
        onyx12: '#A6A6A6',

        // Aliases
        primary1: '$lavender1',
        primary2: '$lavender2',
        primary3: '$lavender3',
        primary4: '$lavender4',
        primary5: '$lavender5',
        primary6: '$lavender6',
        primary7: '$lavender7',
        primary8: '$lavender8',
        primary9: '$lavender9',
        primary10: '$lavender10',
        primary11: '$lavender11',
        primary12: '$lavender12',

        // Secondary
        secondary1: '$sage1',
        secondary2: '$sage2',
        secondary3: '$sage3',
        secondary4: '$sage4',
        secondary5: '$sage5',
        secondary6: '$sage6',
        secondary7: '$sage7',
        secondary8: '$sage8',
        secondary9: '$sage9',
        secondary10: '$sage10',
        secondary11: '$sage11',
        secondary12: '$sage12',

        // Gray
        gray1: '$slate1',
        gray2: '$slate2',
        gray3: '$slate3',
        gray4: '$slate4',
        gray5: '$slate5',
        gray6: '$slate6',
        gray7: '$slate7',
        gray8: '$slate8',
        gray9: '$slate9',
        gray10: '$slate10',
        gray11: '$slate11',
        gray12: '$slate12',

        // Red
        red1: '$crimson1',
        red2: '$crimson2',
        red3: '$crimson3',
        red4: '$crimson4',
        red5: '$crimson5',
        red6: '$crimson6',
        red7: '$crimson7',
        red8: '$crimson8',
        red9: '$crimson9',
        red10: '$crimson10',
        red11: '$crimson11',
        red12: '$crimson12',

        neutralBg: 'white',
        neutralBgSubtle: 'white',
        panelShadow: 'rgba(0,0,0,0.1)',
        panelBg: '$gray2',
        panelBorder: 'transparent',
        dropdownBg: 'white',
        sidebarOverlay: 'black',
      },
      space: {
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '32px',
        6: '64px',
      },
      fontSizes: {},
      fontWeights: {},
      fonts: {
        ocrA: '"ocr-a-std", monospace',
        header: headerFont.style.fontFamily,
        body: bodyFont.style.fontFamily,
        button: '$body',
      },
      lineHeights: {},
      letterSpacings: {},
      sizes: {},
      radii: {},
      shadows: {},
      transitions: {},
      breakpoints: {
        sm: 100,
      },
    },
    utils: {
      // MARGIN
      m: (value: Stitches.PropertyValue<'margin'>) => ({
        margin: value,
      }),
      mx: (value: Stitches.PropertyValue<'margin'>) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: Stitches.PropertyValue<'margin'>) => ({
        marginTop: value,
        marginBottom: value,
      }),
      mt: (value: Stitches.PropertyValue<'margin'>) => ({
        marginTop: value,
      }),
      mb: (value: Stitches.PropertyValue<'margin'>) => ({
        marginBottom: value,
      }),
      ml: (value: Stitches.PropertyValue<'margin'>) => ({
        marginLeft: value,
      }),
      mr: (value: Stitches.PropertyValue<'margin'>) => ({
        marginRight: value,
      }),

      // PADDING
      p: (value: Stitches.PropertyValue<'padding'>) => ({
        padding: value,
      }),
      px: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingTop: value,
        paddingBottom: value,
      }),
      pt: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingTop: value,
      }),
      pb: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingBottom: value,
      }),
      pl: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingLeft: value,
      }),
      pr: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingRight: value,
      }),
      // DIMENSIONS
      w: (value: Stitches.PropertyValue<'width'>) => ({
        width: value,
      }),
      h: (value: Stitches.PropertyValue<'height'>) => ({
        height: value,
      }),
      size: (value: Stitches.PropertyValue<'width'>) => ({
        width: value,
        height: value,
      }),
      // GRID
      colSpan: (value: number | 'full') => {
        if (value === 'full') {
          return {
            gridColumn: '1 / -1',
          }
        }
        return {
          gridColumn: `span ${value} / span ${value}`,
        }
      },
    },
    media: {
      sm: '(min-width: 600px)',
      md: '(min-width: 900px)',
      lg: '(min-width: 1200px)',
      xl: '(min-width: 1820px)',
      bp300: '(min-width: 300px)',
      bp400: '(min-width: 400px)',
      bp500: '(min-width: 500px)',
      bp600: '(min-width: 600px)',
      bp700: '(min-width: 700px)',
      bp800: '(min-width: 800px)',
      bp900: '(min-width: 900px)',
      bp1000: '(min-width: 1000px)',
      bp1100: '(min-width: 1100px)',
      bp1200: '(min-width: 1200px)',
      bp1300: '(min-width: 1300px)',
      bp1400: '(min-width: 1400px)',
      bp1500: '(min-width: 1500px)',
      motion: '(prefers-reduced-motion)',
      hover: '(any-hover: hover)',
      dark: '(prefers-color-scheme: dark)',
      light: '(prefers-color-scheme: light)',
    },
  })

export const globalReset = globalCss(reset)

export const darkTheme = createTheme({
  colors: {
    ...crimsonDark,
    ...violetDark,
    ...violetDarkA,
    ...slateDark,
    ...greenDark,
    ...irisDark,
    ...whiteA,
    ...redDark,
    ...blackA,

    // Sage variations
    sage1: '#F5F8ED',
    sage2: '#EBF1DB',
    sage3: '#E1EACA',
    sage4: '#D7E3B8',
    sage5: '#CDDCA6',
    sage6: '#C3D595',
    sage7: '#ABC470', // Original sage color
    sage8: '#93AC58',
    sage9: '#7B9440',
    sage10: '#637C28',
    sage11: '#4B6410',
    sage12: '#334C00',

    // Lavender variations
    lavender1: '#F4F1FA',
    lavender2: '#E9E3F5',
    lavender3: '#DED5F0',
    lavender4: '#D3C7EB',
    lavender5: '#C8B9E6',
    lavender6: '#BDABE1',
    lavender7: '#9780C4', // Original lavender color
    lavender8: '#7F66AC',
    lavender9: '#674D94',
    lavender10: '#4F337C',
    lavender11: '#371A64',
    lavender12: '#1F004C',

    // Coral variations
    coral1: '#FDF0EE',
    coral2: '#FBE1DD',
    coral3: '#F9D2CC',
    coral4: '#F7C3BB',
    coral5: '#F5B4AA',
    coral6: '#F3A599',
    coral7: '#DF6D5D', // Original coral color
    coral8: '#C75345',
    coral9: '#AF392D',
    coral10: '#971F15',
    coral11: '#7F0500',
    coral12: '#670000',

    // Ivory variations
    ivory1: '#FDFDFB',
    ivory2: '#FBFBF7',
    ivory3: '#F9F9F3',
    ivory4: '#F7F7EF',
    ivory5: '#F5F5EB',
    ivory6: '#F6F4E7', // Original ivory color
    ivory7: '#E2E0D3',
    ivory8: '#CECBBF',
    ivory9: '#BAB7AB',
    ivory10: '#A6A397',
    ivory11: '#928F83',
    ivory12: '#7E7B6F',

    // Onyx variations
    onyx1: '#1A1A1A',
    onyx2: '#262626',
    onyx3: '#333333',
    onyx4: '#404040',
    onyx5: '#4D4D4D',
    onyx6: '#595959',
    onyx7: '#666666',
    onyx8: '#737373',
    onyx9: '#808080',
    onyx10: '#8C8C8C',
    onyx11: '#999999',
    onyx12: '#A6A6A6',

    // Aliases

    // Primary (using lavender variations)
    primary1: '$lavender1',
    primary2: '$lavender2',
    primary3: '$lavender3',
    primary4: '$lavender4',
    primary5: '$lavender5',
    primary6: '$lavender6',
    primary7: '$lavender7',
    primary8: '$lavender8',
    primary9: '$lavender9',
    primary10: '$lavender10',
    primary11: '$lavender11',
    primary12: '$lavender12',

    // Secondary (using sage variations)
    secondary1: '$sage1',
    secondary2: '$sage2',
    secondary3: '$sage3',
    secondary4: '$sage4',
    secondary5: '$sage5',
    secondary6: '$sage6',
    secondary7: '$sage7',
    secondary8: '$sage8',
    secondary9: '$sage9',
    secondary10: '$sage10',
    secondary11: '$sage11',
    secondary12: '$sage12',

    // Gray
    slate0: 'hsl(240, 5.0%, 7.0%)',
    gray1: '$slate0',
    gray2: '$slate0',
    gray3: '$slate0',
    gray4: '$slate2',
    gray5: '$slate3',
    gray6: '$slate5',
    gray7: '$slate6',
    gray8: '$slate8',
    gray9: '$slate9',
    gray10: '$slate10',
    gray11: '$slate11',
    gray12: '$slate12',

    accent: '#7000FF',

    neutralBg: 'black',
    neutralBgSubtle: '$gray3',

    panelBg: '$onyx1',
    panelBorder: '$slate7',
    panelShadow: 'transparent',
    dropdownBg: '$gray3',
    sidebarOverlay: 'black',
  },
})
