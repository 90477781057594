import { styled } from 'stitches.config'

export default styled('span', {
  color: '$gray12',
  fontFamily: '$body',
  letterSpacing: 0,

  variants: {
    style: {
      h2: {
        fontFamily: '$header',
        fontWeight: 800,
        fontSize: 48,
      },
      h3: {
        fontFamily: '$header',
        fontWeight: 800,
        fontSize: 32,
      },
      h4: {
        fontFamily: '$header',
        fontWeight: 800,
        fontSize: 24,
      },
      h5: {
        fontWeight: 700,
        fontSize: 20,
      },
      h5ocr: {
        fontFamily: '$ocrA',
        fontWeight: 700,
        fontSize: 20,
      },
      h6: { fontWeight: 700, fontSize: 16 },
      h7: {
        fontFamily: '$header',
        fontWeight: 700,
        fontSize: 18,
      },
      h3mono: {
        fontFamily: '$ocrA',
        fontWeight: 800,
        fontSize: 32,
      },
      h4mono: {
        fontFamily: '$ocrA',
        fontWeight: 700,
        fontSize: 24,
      },
      h5mono: {
        fontFamily: '$ocrA',
        fontWeight: 700,
        fontSize: 20,
      },
      h6mono: {
        fontFamily: '$ocrA',
        fontWeight: 700,
        fontSize: 16,
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: 16,
      },
      subtitle1mono: {
        fontFamily: '$ocrA',
        fontWeight: 700,
        fontSize: 14,
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: 14,
      },
      subtitle2mono: {
        fontFamily: '$ocrA',
        fontWeight: 500,
        fontSize: 14,
      },
      subtitle3: {
        fontWeight: 500,
        fontSize: 12,
      },
      subtitle3mono: {
        fontFamily: '$ocrA',
        fontWeight: 500,
        fontSize: 12,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
      },
      body1mono: {
        fontFamily: '$ocrA',
        fontWeight: 400,
        fontSize: 14,
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
      },
      body2mono: {
        fontFamily: '$ocrA',
        fontWeight: 400,
        fontSize: 14,
      },
      body3: {
        fontWeight: 400,
        fontSize: 12,
      },
      body3mono: {
        fontFamily: '$ocrA',
        fontWeight: 400,
        fontSize: 12,
      },
    },
    color: {
      subtle: {
        color: '$gray11',
      },
      error: {
        color: '$red11',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    ellipsify: {
      true: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },

  defaultVariants: {
    style: 'body1',
  },
})
